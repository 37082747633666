<template>
  <div v-if="banners.length">
    <div class="grid md:grid-cols-4 grid-cols-2 gap-2 md:gap-4">
      <component
        :is="linkConfig(banner).element"
        v-for="(banner, i) in banners"
        :key="i"
        v-bind="linkConfig(banner).attrs"
      >
        <picture>
          <source v-if="desktopTileBannerImageUrl(banner)" :srcset="desktopTileBannerImageUrl(banner)" media="(min-width: 1024px)">
          <img :src="mainTileBannerImageUrl(banner)" :alt="altText(banner)">
        </picture>
      </component>
    </div>
  </div>
</template>

<script setup>
import { useLexas } from '~/composables/useLexas'

const route = useRoute()
const { getTileBannerListByPath } = useLexas()
// Fetch tile banners based on the current route path
const banners = await getTileBannerListByPath(route.path)

const mainTileBannerImageUrl = computed(() => banner => banner.mobileImage?.url || '')
const desktopTileBannerImageUrl = computed(() => banner => banner.desktopImage?.url || '')
const altText = computed(() => banner => banner.mobileImage?.altText || banner.desktopImage?.altText || '')

// Check if the link is external
const isExternal = link => /^(http|https):\/\//.test(link)
const NuxtLink = resolveComponent('NuxtLink')

const linkConfig = computed(() => (banner) => {
  const link = banner.link
  return link
    ? isExternal(link)
      // If the link is external, return an anchor element
      ? { element: 'a', attrs: { href: link, target: '_blank', rel: 'noopener noreferrer' } }
      // If the link is internal, return NuxtLink
      : { element: NuxtLink, attrs: { to: link } }
    // If there's no link, return a div element
    : { element: 'div', attrs: {} }
})
</script>
