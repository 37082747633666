<template>
  <div v-if="mainHeroBannerImageUrl">
    <component :is="linkConfig.element" v-bind="linkConfig.attrs">
      <picture>
        <source v-if="desktopHeroBannerImageUrl" :srcset="desktopHeroBannerImageUrl" media="(min-width: 640px)">
        <img :src="mainHeroBannerImageUrl" :alt="altText">
      </picture>
    </component>
  </div>
</template>

<script setup>
import { useLexas } from '~/composables/useLexas'

const route = useRoute()
const { getHeroBannerListByPath } = useLexas()
// Fetch hero banners based on the current route path
const banners = await getHeroBannerListByPath(route.path)
const { mobileImage = {}, desktopImage = {}, link = '' } = banners?.[0] || {}

const mainHeroBannerImageUrl = computed(() => mobileImage.url || '')
const desktopHeroBannerImageUrl = computed(() => desktopImage?.url || '')
const altText = computed(() => mobileImage?.altText || desktopImage?.altText || '')

// Check if the link is an external
const isExternal = computed(() => /^(http|https):\/\//.test(link))
const NuxtLink = resolveComponent('NuxtLink')

const linkConfig = computed(() => {
  return link
    ? isExternal.value
      // If the link is external, use an anchor tag with target and rel attributes
      ? { element: 'a', attrs: { href: link, target: '_blank', rel: 'noopener noreferrer' } }
      // If the link is internal, use NuxtLink
      : { element: NuxtLink, attrs: { to: link } }
    // If there's no link, return a div
    : { element: 'div', attrs: {} }
})
</script>
